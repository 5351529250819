<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col class="col-12 mh-main-col">
        <v-row class="mh-special-title-row">
          <v-card-title
            style="width: 100%"
            class="text-lg-h4 mh-page-title text-md-h5 justify-center pb-2 flex-row justify-lg-space-between pt-0"
          >
            <span class="mr-2">{{ pageData.title }} </span>
            <help-btn :new-version="true" v-if="false"></help-btn>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="toggle_period"
              mandatory
              dense
              data-html2canvas-ignore
            >
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Вчера
              </v-btn>
              <!--              <v-btn v-if="false"-->
              <!--                elevation="0"-->
              <!--                class="mh-noactive-toggle"-->
              <!--                :active-class="'white black&#45;&#45;text mh-active-toggle'"-->
              <!--              >-->
              <!--                Сегодня-->
              <!--              </v-btn>-->
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Неделя
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Месяц
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Год
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
        </v-row>
        <v-card outlined v-if="loading === false" class="mt-4">
          <v-container>
            <v-row>
              <v-col class="col-12 pt-0">
                <v-col class="col-12 pt-0">
                  <v-card-title
                    style="width: 100%"
                    class="pt-0 pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pb-2 flex-row text-wrap justify-lg-space-between"
                  >
                    <v-col class="col-12 col-md-7 col-xl-9">
                      Сравнение регионов по доле телесмотрения
                      {{ pageType == "gtrk" ? "новостных" : "" }}
                      передач
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-switch
                      color="primary"
                      class="pr-2"
                      value
                      label="Плитка"
                      v-model="toggle_map_type"
                    ></v-switch>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          style="width: 140px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ toggle_type_btn_txt }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item link @click="toggle_type = 0">
                          <v-list-item-title>Регионы РФ</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          link
                          @click="toggle_type = 1"
                          v-if="pageType == 'gtrk'"
                        >
                          <v-list-item-title>«Россия 1»</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="toggle_type = 2">
                          <v-list-item-title>Прошлый период</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-col cols="auto" v-if="false">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation="0"
                            max-width="40"
                            min-width="40"
                            class="ml-4"
                            style="text-transform: none !important"
                            v-bind="attrs"
                            v-on="on"
                            data-html2canvas-ignore
                          >
                            <v-icon small>mdi-download</v-icon>
                          </v-btn
                          >
                        </template>
                        <v-list dense>
                          <v-list-item
                              dense
                              link
                              @click="toExcel"
                              v-if="!isAlternate"
                          >
                            <v-list-item-title
                            >Таблица (xlsx)
                            </v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item dense link @click="saveChartToPng">
                            <v-list-item-title
                            >Изображение (png)
                            </v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-card-title>
                  <v-row v-if="!$vuetify.breakpoint.lgAndUp">
                    <v-col>
                      <span style="line-height: 20px" class="pl-2 caption"
                      ><v-icon small class="pr-1 mt-n1"
                      >mdi-clock-outline</v-icon
                      >Выпуск {{ showsHelper }}</span
                      >
                      <v-slider
                        color="rgba(0, 0, 0, 0.18)"
                        thumb-color="secondary"
                        track-color="rgba(0, 0, 0, 0.18)"
                        v-model="newsTimeIndex"
                        :tick-labels="tickLabels"
                        step="1"
                        :min="0"
                        max="3"
                        hide-details
                        tick-size="0"
                      >
                      </v-slider>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      v-if="toggle_map_type"
                      class="col-12 col-md-8 pt-0 pb-0"
                      style="margin: 0 auto"
                    >
                      <highcharts
                        ref="tileMap"
                        :options="drawChartOptionsTiles"
                      ></highcharts>
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="toggle_map_type && $vuetify.breakpoint.lgAndUp"
                    >
                      <span style="line-height: 20px" class="ml-n6 caption"
                      ><v-icon small class="pr-1 mt-n1"
                      >mdi-clock-outline</v-icon
                      ></span
                      >
                      <v-timeline class="ml-n16" dense>
                        <v-timeline-item
                            small
                            class="mt-8 mb-8"
                            @click.native.stop="setNewsIndex(0)"
                            style="cursor: pointer; font-size: 12px"
                            :color="
                            newsTimeStr == 'all'
                              ? 'secondary'
                              : 'green lighten-3'
                          "
                            right
                        >
                          Все
                        </v-timeline-item>

                        <v-timeline-item
                          small
                          class="mt-8 mb-8"
                          @click.native.stop="setNewsIndex(1)"
                          style="cursor: pointer; font-size: 12px"
                          :color="
                          newsTimeStr == 'morning'
                            ? 'secondary'
                            : 'green lighten-3'
                        "
                          right
                        >
                          Утро
                        </v-timeline-item>
                        <v-timeline-item
                          small
                          class="mt-8 mb-8"
                          @click.native.stop="setNewsIndex(2)"
                          style="cursor: pointer; font-size: 12px"
                          :color="
                          newsTimeStr == 'day'
                            ? 'secondary'
                            : 'green lighten-3'
                        "
                          right
                        >
                          День
                        </v-timeline-item>
                        <v-timeline-item
                          small
                          class="mt-8 mb-8"
                          @click.native.stop="setNewsIndex(3)"
                          style="cursor: pointer; font-size: 12px"
                          :color="
                          newsTimeStr == 'evening'
                            ? 'secondary'
                            : 'green lighten-3'
                        "
                          right
                        >
                          Вечер
                        </v-timeline-item>
                      </v-timeline>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                        v-if="!toggle_map_type"
                        class="col-12 col-md-8"
                        style="margin: 0 auto"
                    >
                      <highcharts
                        ref="defMap"
                        :constructorType="'mapChart'"
                        :options="drawChartOptionsMap"
                      ></highcharts>
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="!toggle_map_type && $vuetify.breakpoint.lgAndUp"
                    >
                      <span style="line-height: 20px" class="ml-n6 caption"
                      ><v-icon small class="pr-1 mt-n1"
                      >mdi-clock-outline</v-icon
                      ></span
                      >
                      <v-timeline class="ml-n16" dense>
                        <v-timeline-item
                            small
                            @click.native.stop="setNewsIndex(0)"
                            style="cursor: pointer; font-size: 12px"
                            :color="
                            newsTimeStr == 'all'
                              ? 'secondary'
                              : 'green lighten-3'
                          "
                            right
                        >
                          Все
                        </v-timeline-item>

                        <v-timeline-item
                            small
                            class="mt-8 mb-8"
                            @click.native.stop="setNewsIndex(1)"
                            style="cursor: pointer; font-size: 12px"
                            :color="
                            newsTimeStr == 'morning'
                              ? 'secondary'
                              : 'green lighten-3'
                          "
                            right
                        >
                          Утро
                        </v-timeline-item>
                        <v-timeline-item
                            small
                            class="mt-8 mb-8"
                            @click.native.stop="setNewsIndex(2)"
                            style="cursor: pointer; font-size: 12px"
                            :color="
                            newsTimeStr == 'day'
                              ? 'secondary'
                              : 'green lighten-3'
                          "
                            right
                        >
                          День
                        </v-timeline-item>
                        <v-timeline-item
                            small
                            class="mt-8 mb-8"
                            @click.native.stop="setNewsIndex(3)"
                            style="cursor: pointer; font-size: 12px"
                            :color="
                            newsTimeStr == 'evening'
                              ? 'secondary'
                              : 'green lighten-3'
                          "
                            right
                        >
                          Вечер
                        </v-timeline-item>
                      </v-timeline>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div id="comparison_slider" :style="'width: 100%'"></div>
                      <div
                        v-if="false"
                        class="chartHelper mt-4"
                        style="width: 100%"
                      >
                        <v-icon small color="#FFA91B" class="pr-1"
                        >mdi-lightbulb
                        </v-icon
                        >
                        Настройте тепловое окрашивание блока телепередачи в
                        зависимости от среднего значения показателя за выбранный
                        период.
                        <v-icon v-if="false" class="ml-2" small @click="hideTip"
                        >mdi-close
                        </v-icon
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="regionStr"
                    class="orange mt-6"
                    justify="space-between"
                    style="min-height: 58px"
                  >
                    <v-col cols="auto" style="line-height: 38px"
                    ><h4>Выбран регион: {{ regionStr }} {{channelNameStr}}</h4>
                    </v-col>
                    <v-col align="end" v-if="false">
                      <v-btn
                        elevation="0"
                        max-width="204"
                        min-width="204"
                        style="
                        text-transform: none !important;
                        border: 1px solid rgba(0, 0, 0, 0.87) !important;
                        "
                        @click="region_chip = false"
                        data-html2canvas-ignore
                      >
                        Смотреть всю Россию
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="col-12">
                  <v-card-title
                      style="width: 100%"
                      class="pt-0 mt-0 pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pb-2 flex-row text-wrap justify-lg-space-between"
                  >
                    <v-col class="col-12 col-md-7 col-xl-9">
                      Телесмотрение {{ showsHelper }}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-btn-toggle
                        v-model="toggle_news_type"
                        mandatory
                        dense
                        data-html2canvas-ignore
                      >
                        <v-btn
                          elevation="0"
                          class="mh-noactive-toggle"
                          :active-class="'white black--text mh-active-toggle'"
                        >
                          Аудитория
                        </v-btn>
                        <v-btn
                          elevation="0"
                          class="mh-noactive-toggle"
                          :active-class="'white black--text mh-active-toggle'"
                        >
                          Доля
                        </v-btn>
                        <v-btn
                          elevation="0"
                          class="mh-noactive-toggle"
                          :active-class="'white black--text mh-active-toggle'"
                        >
                          Рейтинг
                        </v-btn>
                        <v-btn
                          elevation="0"
                          class="mh-noactive-toggle"
                          :active-class="'white black--text mh-active-toggle'"
                        >
                          Охват
                        </v-btn>
                      </v-btn-toggle>
                      <v-menu offset-y v-if="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation="0"
                            max-width="40"
                            min-width="40"
                            class="ml-4"
                            style="text-transform: none !important"
                            v-bind="attrs"
                            v-on="on"
                            data-html2canvas-ignore
                          >
                            <v-icon small>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            dense
                            link
                            @click="toExcel"
                            v-if="!isAlternate"
                          >
                            <v-list-item-title>
                              Таблица (xlsx)
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item dense link @click="saveChartToPng">
                            <v-list-item-title>
                              Изображение (png)
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-card-title>
                  <v-row>
                    <v-col class="col-12 col-lg-6">
                      <highcharts :options="newsShare"></highcharts>
                    </v-col>
                    <v-col class="col-12 col-lg-6">
                      <div v-if="selectedProg">
                        <div v-if="pageType == 'gtrk'">
                          <v-icon color="primary">mdi-newspaper</v-icon>
                          <a
                            class="primary--text showNews"
                            style=""
                            @click="showInternetNews"
                          >Посмотреть интернет-новости</a
                          >
                        </div>
                        <div
                          class="text-lg-h5 text-md-h6 mt-4 black--text font-weight-bold"
                          v-if="toggle_news_type != 3"
                        >
                          Поминутная детализация телепередачи
                        </div>

                        <div
                          class="chartSubtitle mt-2"
                          v-if="chart2ready && toggle_news_type != 3"
                        >
                          <span
                              v-if="hasVideoChannel"
                              style="
                              font-size: 1.1em;
                              font-family: 'Material Design Icons';
                            "
                          >&#xF0567;</span
                          >
                          <span
                              v-if="!hasVideoChannel"
                              style="
                              font-size: 1.1em;
                              font-family: 'Material Design Icons';
                            "
                          >&#xF0568;</span
                          >
                          {{
                            selectedProg.progName +
                            " " +
                            drawProgDates(selectedProg)
                          }}
                        </div>
                        <highcharts
                            :options="chartOptionsHour"
                            v-if="chart2ready && toggle_news_type != 3"
                            ref="chartHour"
                        />
                        <v-divider class="mt-4" />
                        <div
                            class="text-lg-h5 text-md-h6 mt-4 black--text font-weight-bold"
                        >
                          Приток-отток зрителей
                        </div>

                        <div class="chartSubtitle mt-2" v-if="chart2ready">
                          <span
                              v-if="hasVideoChannel"
                              style="
                              font-size: 1.1em;
                              font-family: 'Material Design Icons';
                            "
                          >&#xF0567;</span
                          >
                          <span
                              v-if="!hasVideoChannel"
                              style="
                              font-size: 1.1em;
                              font-family: 'Material Design Icons';
                            "
                          >&#xF0568;</span
                          >
                          {{
                            selectedProg.progName +
                            " " +
                            drawProgDates(selectedProg)
                          }}
                        </div>
                        <highcharts
                            :options="chartOptionsIncomes"
                            v-if="chart3ready"
                            ref="chartIncomes"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card outlined class="mt-2" :height="'100%'" v-if="loading === true">
          <base-loader></base-loader>
        </v-card>
      </v-col>
    </v-row>
    <video-player
        :shown="videoPlayerOn"
        :dt="videoPlayerDt"
        :videoChannelId="videoPlayerChannel"
        :videoChannelName="videoPlayerChannelName"
        :valueName="videoPlayerValueName"
        :values="videoPlayerValues"
        :program="videoPlayerProgram"
        @close="videoPlayerOn = false"
    />
    <v-dialog v-model="showNews" max-width="800" max-height="488">
      <v-card>
        <v-card-title class="text-h5">
          Интернет-новости за {{ periodStrRodPad }}
          <v-spacer></v-spacer>
          <v-btn large icon @click="showNews = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <base-loader v-if="internet_news_loading"></base-loader>
          <v-list dense v-if="!internet_news_loading">
            <a
                сlass="pt-2 pb-2"
                v-for="(news, index) in internet_news_data"
                :key="index"
                @click="openOriginalPage(news.page)"
            >
              <a>{{ news.news }}</a
              ><br />
            </a>
          </v-list>
          <p v-if="!internet_news_loading && internet_news_data.length == 0">
            Интернет-новостей за указанный период не найдено.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { Chart } from "highcharts-vue";
import mapMarkup from "../../public/jsons/russia.json";
import proj4 from "proj4";
import noUiSlider from "nouislider";
import { CONSTS } from "../services/constants";
import wNumb from "wnumb";
import moment from "moment";
import BaseLoader from "@/components/BaseLoader.vue";
import Utils from "../services/utils";
import html2canvas from "html2canvas";
import VideoPlayer from "@/components/VideoPlayer.vue";
import HelpBtn from "@/components/HelpBtn.vue";
// eslint-disable-next-line no-unused-vars
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

export default {
  name: "SpecialTV",
  components: {
    HelpBtn,
    VideoPlayer,
    BaseLoader,
    highcharts: Chart
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    mapChartColors() {
      if (this.pageType == "gtrk") {
        return [
          [0, CONSTS.SECTION_GTRK_COLORS[0]],
          [0.00001, CONSTS.SECTION_GTRK_COLORS[1]],
          [1 + this.ranges[0] / 100, CONSTS.SECTION_GTRK_COLORS[1]],
          [1 + this.ranges[1] / 100, CONSTS.SECTION_GTRK_COLORS[2]],
          [1, CONSTS.SECTION_GTRK_COLORS[3]],
          [1 + this.ranges[2] / 100, CONSTS.SECTION_GTRK_COLORS[4]],
          [1 + this.ranges[3] / 100, CONSTS.SECTION_GTRK_COLORS[5]],
        ];
      } else {
        return [
          [0, CONSTS.SECTION_GTRK_COLORS_2[0]],
          [0.00001, CONSTS.SECTION_GTRK_COLORS_2[1]],
          [1 + this.ranges[0] / 100, CONSTS.SECTION_GTRK_COLORS_2[1]],
          [1 + this.ranges[1] / 100, CONSTS.SECTION_GTRK_COLORS_2[2]],
          [1, CONSTS.SECTION_GTRK_COLORS_2[3]],
          [1 + this.ranges[2] / 100, CONSTS.SECTION_GTRK_COLORS_2[4]],
          [1 + this.ranges[3] / 100, CONSTS.SECTION_GTRK_COLORS_2[5]],
        ];
      }
    },
    pageType() {
      return this.$route.params.type;
    },
    showsHelper() {
      if ( this.pageType == "gtrk") {
        return "новостей";
      }
      return "передач";
    },
    toggle_type_btn_txt() {
      switch (this.toggle_type) {
        case 1:
          return "«Россия 1»";
        case 2:
          return "Прошлый период";
        default:
          return "Регионы РФ";
      }
    },
    toggle_type_str() {
      switch (this.toggle_type) {
        case 1:
          return "r1";
        case 2:
          return "last";
        default:
          return "regions";
      }
    },
    hasVideoChannel() {
      return !!this.chartHour.charts[0].series[0].video_channel_id;
    },
    uniqShows() {
      const uniqs = [];
      const uniqsIds = new Set();
      for (const tvshow of this.chart.programs) {
        if (!uniqsIds.has(tvshow.program_id)) {
          uniqsIds.add(tvshow.program_id);
          uniqs.push(tvshow);
        }
      }
      return uniqs;
    },
    filename() {
      const city = { name: "Москва" };
      const channels = this.selectedProg
          ? [this.tvNames[this.selectedProg.tvId]]
          : [];
      const shows = this.selectedProg ? this.chartProg.programs : [];
      const filename = Utils.createFilename({
        range: this.range,
        cities: city,
        channels: channels,
        shows: shows,
        indicator: this.indicatorName,
        reportTime: this.reportTime
      });
      return filename;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    chartName() {
      return Utils.indicatorName(this.indicator);
    },
    indicatorName() {
      return Utils.indicatorName(this.indicator);
    },
    chartTitle() {
      // const start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      // const end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      // const startTime = moment(this.range.start).locale("ru").format("HH:mm");
      // const endTime = moment(this.range.end).locale("ru").format("HH:mm");
      // const weekdays = this.drawWeekdays;
      // return `Москва, ${start}, ${startTime} - ${end}, ${endTime} ${weekdays}`;
      return "";
    },
    chartSubtitle() {
      const start = moment(this.selectedProg.sdate)
          .locale("ru")
          .format("DD MMM YYYY");
      const end = moment(this.selectedProg.edate)
          .locale("ru")
          .format("DD MMM YYYY");
      const startTime = moment(this.selectedProg.sdate)
          .locale("ru")
          .format("HH:mm");
      const endTime = moment(this.selectedProg.edate)
          .locale("ru")
          .format("HH:mm");
      return `${start}, ${startTime} - ${end}, ${endTime}`;
    },
    drawWeekdays() {
      return Utils.drawWeekdays(this.range.weekdays);
    },
    chartSubTitle() {
      return "";
    },
    chartOptionsHour() {
      const that = this;
      const data = this.chartHour?.charts[0];
      if (!data) return {};

      let ticks = 12;
      let mult = (data.categories.length / 8) | 0;
      if (data.categories.length <= 15) {
        ticks = 15;
        mult = 1;
      }
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult);
      let chartMax = 0;
      for (const seriesData of data.series) {
        const seriesMax = Math.max(...seriesData.data.map((s) => s.y));
        if (seriesMax > chartMax) chartMax = seriesMax;
      }

      const chart = {
        id: "minuteChart",
        plotBands: [],
        chart: {
          zoomType: "x",
          marginRight: 15,
          type: "areaspline",
          events: {
            click: function(e) {
              console.log("click");
              if (e.srcElement.firstChild) return; // reset zoom pressed
              const data = that.chartHour.charts[0];
              const videoChannelId = data.series[0].video_channel_id;
              const channelName = data.series[0].name;
              const point = parseInt(e.xAxis[0].value);
              const programs = data.programs;
              const values = that.prepareValuesData(
                  data.categories,
                  data.series[0]
              );
              const dt = moment(data.categories[point])
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss");
              that.playPoint(
                  videoChannelId,
                  dt,
                  channelName,
                  that.indicatorName,
                  programs,
                  values
              );
            }
          },
          height: this.heightChart
        },
        title: { text: "" },
        subtitle: { text: "" },
        legend: { enabled: false },
        colors: CONSTS.CHART_COLORS,
        xAxis: {
          tickPositions,
          tickWidth: 1,
          tickmarkPlacement: "on",
          min: 0.5,
          max: data.categories.length - 1.5,
          startOnTick: false,
          endOnTick: false,
          minPadding: 0,
          maxPadding: 0,
          align: "left",
          categories: data.categories,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function() {
              let value = this.value;
              let h, m;
              if (value.toString().length == 5) {
                h = value.substring(0, 2);
                m = value.substring(3, 5);
              } else {
                value = moment(value).utc().format("YYYY-MM-DD HH:mm");
                h = value.substring(11, 13);
                m = value.substring(14, 16);
              }

              if (this.isFirst) {
                return `
                <div style="padding-top: 5px">
                  <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                  <b style="font-size: 1.1em;">${h}</b>
                  <br>

                  <span style="padding-right: 6px; margin-left: -20px;">мин\u00a0</span>${m}
                </div>

                `;
              }
              return `
                <div style="padding-top: 4px; margin-left: 10px;">

                <span style=""><b style="font-size: 1.1em;">${h}</b></span>
                <br>
                <span style="">${m}</span>

                </div>
              `;
            }
          }
        },
        yAxis: {
          min: 0,
          max: chartMax,
          reversedStacks: false,
          title: { text: this.indicatorName },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080"
            }
          ]
        },
        plotOptions: {
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, CONSTS.CHART_COLORS[0]],
                [1, CONSTS.CHART_COLORS[0].replace("1)", "0)")]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          },
          series: {
            stickyTracking: false,
            inside: false,
            states: {
              inactive: {
                opacity: 1
              }
            },
            events: {
              click: function(event) {
                const data = that.chartHour.charts[0];
                const videoChannelId = data.series[0].video_channel_id;
                const channelName = data.series[0].name;
                const programs = data.programs;
                const values = that.prepareValuesData(
                    data.categories,
                    data.series[0]
                );
                const dt = moment(event.point.category)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                that.playPoint(
                    videoChannelId,
                    dt,
                    channelName,
                    that.indicatorName,
                    programs,
                    values
                );
              }
            },
            turboThreshold: 3600 * 24,
            marker: {
              radius: 1,
              enabled: false
            }
          }
        },
        tooltip: {
          useHTML: true,
          formatter(e) {
            function fillProgData(dt) {
              const tvId = that.selectedProg.tvId;
              const prog = data.programs.find(
                  (item) =>
                      item.tvid == tvId &&
                      item.start_loc <= dt &&
                      item.stop_loc >= dt
              );
              return prog;
            }

            let hhmm = this.x;
            let prog = null;
            hhmm = moment(this.x).utc().format("HH:mm");
            const dt = moment(this.x).utc().format("YYYY-MM-DD HH:mm:ss");
            prog = fillProgData(dt, 0, e.chart.options.id);
            if (prog) {
              return `
                ${hhmm}<br>
                ${that.indicatorName}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b> ${this.series.name}</b><br>
                <b>${prog.title}</b><br>
                ${prog.subtitle}
              `;
            }
            return `
                ${hhmm}<br>
                <b> ${this.series.name}</b>: ${this.y}<br>
                `;
          }
        },
        series: data.series
      };
      return chart;
    },
    chartOptionsIncomes() {
      const that = this;
      const data = this.chartIncomes?.charts[0];
      if (!data) return {};
      let ticks = 12;
      let mult = (data.categories.length / 8) | 0;
      if (data.categories.length <= 15) {
        ticks = 15;
        mult = 1;
      }
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult);

      const chart = {
        id: "incomesChart",
        chart: {
          zoomType: "x",
          type: "column",
          events: {},
          height: 400
        },
        title: { text: "" },
        subtitle: { text: "" },
        colors: CONSTS.CHART_INOUT_COLORS,
        xAxis: {
          tickPositions,
          tickWidth: 1,
          tickmarkPlacement: "on",
          min: 0.5,
          max: data.categories.length - 1.5,
          startOnTick: false,
          endOnTick: false,
          categories: data.categories,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function() {
              const value = this.value;
              if (this.isFirst) {
                return `
                <div style="padding-top: 5px">
                  <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                  <b style="font-size: 1.1em;">${value?.substring(11, 13)}</b>
                  <br>
                  <span style="padding-right: 6px; margin-left: -20px;">мин\u00a0</span>${value?.substring(
                    14,
                    16
                )}
                </div>
                `;
              }
              return `
                <div style="padding-top: 4px; margin-left: 10px;">
                <span style=""><b style="font-size: 1.1em;">${value?.substring(
                  11,
                  13
              )}</b></span>
                <br>
                <span style="">${value?.substring(14, 16)}</span>
                </div>
              `;
            }
          }
        },
        yAxis: {
          reversedStacks: false,
          title: { text: "Проценты" },
          max: 20,
          min: -20,
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080"
            }
          ]
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          },
          column: {
            grouping: false
          },
          series: {
            events: {
              click: function(event) {
                const data = that.chartIncomes?.charts[0];
                const videoChannelId = data.series[0].video_channel_id;
                const channelName = data.series[0].subtitle ?? null;
                const programs = data.programs;
                //костыль связан со сложным преобразованием дат из исходного формата
                const categories = data.categories.map(
                    (item) => moment(`${item}Z`).unix() * 1000
                );
                const values = that.prepareValuesData(
                    categories,
                    data.series[0],
                    data.series[1]
                );
                const dt = event.srcElement.point.category;
                that.playPoint(
                    videoChannelId,
                    dt,
                    channelName,
                    "inout",
                    programs,
                    values
                );
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            dataLabels: { enabled: false },
            turboThreshold: 0
          },
          area: {
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            }
          }
        },
        tooltip: {
          useHTML: true,
          formatter(e) {
            function fillProgData(dt) {
              const tvId = that.selectedProg.tvId;
              const prog = data.programs.find(
                  (item) =>
                      item.tvid == tvId &&
                      item.start_loc <= dt &&
                      item.stop_loc >= dt
              );
              return prog;
            }

            const hhmm = moment(this.x, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const dt = this.x;
            let prog = null;
            prog = fillProgData(dt, 0, e.chart.options.id);
            if (prog) {
              return `
                ${hhmm}<br>
                ${this.series.name}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b> ${this.series.name}</b><br>
                <b>${prog.title}</b><br>
                ${prog.subtitle}
              `;
            }
            return `
                ${hhmm}<br>
                <b> ${this.series.name}</b>: ${this.y}<br>
                `;
          },
          positioner: function(labelWidth, labelHeight, point) {
            let tooltipX = point.plotX;
            let tooltipY = point.plotY;

            return {
              x: tooltipX,
              y: tooltipY
            };
          }
        },
        series: data.series
      };
      return chart;
    },
    newsTimeStr() {
      switch (this.newsTimeIndex) {
        case 1:
          return "morning";
        case 2:
          return "day";
        case 3:
          return "evening";
        default:
          return "all";
      }
    },
    periodStrRodPad() {
      switch (this.toggle_period) {
        case 0:
          return "вчера";
        case 1:
          return "неделю";
        case 2:
          return "месяц";
        case 3:
          return "год";
      }
      return "вчера";
    },
    periodStr() {
      switch (this.toggle_period) {
        case 0:
          return "yesterday";
        case 1:
          return "week";
        case 2:
          return "month";
        case 3:
          return "year";
      }
      return "yesterday";
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS[`section_${this.pageType}`]
      ];
    },
    pcPercent() {
      return this.devices.devices.find((el) => el.type == "pc").value * 100;
    },
    mobilePercent() {
      return this.devices.devices.find((el) => el.type == "mobile").value * 100;
    },
    tabletPercent() {
      return this.devices.devices.find((el) => el.type == "tablet").value * 100;
    },

    tagsChart() {
      const that = this;
      return {
        chart: {
          height: "50%",
          x: 0,
          y: 0,
          marginLeft: 0,
          marginTop: 0
        },
        plotOptions: {
          wordcloud: {
            minFontSize: 8,
            maxFontSize: 15
          }
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        series: [
          {
            type: "wordcloud",
            data: that.tagsData,
            name: "Количество"
          }
        ],
        title: {
          text: ""
        }
      };
    },
    newsDevices() {
      let news = this.news_data;
      news = news.sort((a, b) => {
        return b.count - a.count;
      });
      const series = [
        {
          data: news.map((el) => {
            return { y: el.count };
          }),
          name: "Количество просмотров",
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, CONSTS.CHART_BAR_COLORS[1]],
              [1, CONSTS.CHART_BAR_COLORS[0]]
            ]
          }
        }
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = news.map((el) => {
        return el.news;
      });
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (30 + 18) + 120,
          stacking: "percent"
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: -6,
            y: 4,
            style: {
              color: "#000",
              fontSize: "14px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "30px"
            },
            formatter: function() {
              const data = this.value.split("|");
              return `&nbsp;&nbsp;${data[0]}`;
            },
            events: {
              mouseover: function() {
                this.axis.chart.series[0].onMouseOver();
              }
            }
          }
        },
        yAxis: {
          opposite: true,
          reversedStacks: false,
          title: { text: "" }
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let [title, subtitle, hhmm] = this.x.split(" | ");
            if (!hhmm) {
              hhmm = subtitle;
              subtitle = "";
            }
            const sdt = hhmm.substring(0, 19);
            const edt = hhmm.substring(22, 41);
            const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );
            const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );

            if (sdate != edate) {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    `;
            } else {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            }
            return `
              ${hhmm}<br>
              Доля: <b>${this.y}</b>
              <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
              <b>${title}</b><br>
              ${subtitle}
            `;
          }
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
            }
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            stacking: false,
            dataLabels: {
              enabled: false
            },
            pointWidth: 30
          }
        },
        series: series
      };
    },

    newsShare() {
      let news = this.news_data;
      let value = "aud";
      let name = "Аудитория";
      const that = this;

      switch (this.toggle_news_type) {
        case 1:
          value = "share";
          name = "Доля";

          break;
        case 2:
          value = "tvr";
          name = "Рейтинг";

          break;
        case 3:
          value = "reach";
          name = "Охват";

          break;
        default:
          value = "aud";
          name = "Аудитория";
          break;
      }
      news = news.sort((a, b) => {
        return b[value] - a[value];
      });
      news = news.splice(0, 18);

      if (news.length > 0) {
        const sdate = news[0].start_loc;
        const edate = news[0].end_loc;
        const sdateExtra = moment(sdate, "YYYY-MM-DD HH:mm:ss")
            .subtract(that.extraTime, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
        const edateExtra = moment(edate, "YYYY-MM-DD HH:mm:ss")
            .add(that.extraTime, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedProg = {
          id: news[0].program_id,
          chartIndex: 1,
          y: news[0][value],
          tvId: news[0].tvid,
          progName: news[0].name,
          progId: null,
          sdate,
          edate,
          sdateExtra,
          edateExtra,
          short_text: ""
        };
      }

      const series = [
        {
          data: news.map((el) => {
            return { y: Number(el[value]), prog: el };
          }),
          name: "",
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, CONSTS.CHART_BAR_COLORS[1]],
              [1, CONSTS.CHART_BAR_COLORS[0]]
            ]
          }
        }
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = news.map((el) => {
        const start = el.start_loc.substr(0, 19);
        const end = el.end_loc.substr(0, 19);
        // console.log(el.name + ' |  | ' + el.start_loc.substr(0,19) + ' - ' + (el.end_loc.substr(0,19));
        return el.name + " |  | " + start + " - " + end;
      });
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (30 + 18) + 120
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: -6,
            y: 4,
            style: {
              color: "#000",
              fontSize: "14px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "30px"
            },
            formatter: function() {
              let [title, subtitle, hhmm] = this.value.split(" | ");
              if (!hhmm) {
                hhmm = subtitle;
                subtitle = "";
              }
              const sdt = hhmm.substring(0, 19);
              const edt = hhmm.substring(22, 41);
              const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM");
              const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format("DD.MM");

              if (sdate != edate) {
                hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                    "DD.MM HH:mm"
                )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                    "DD.MM HH:mm"
                )}
                    `;
              } else {
                hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                    "DD.MM HH:mm"
                )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
              }
              return `&nbsp;&nbsp;${title} [${hhmm}]`;
            },
            events: {
              click: function() {
                const prog = news[this.pos];
                const sdate = prog.start_loc;
                const edate = prog.end_loc;
                const sdateExtra = moment(sdate, "YYYY-MM-DD HH:mm:ss")
                    .subtract(that.extraTime, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss");
                const edateExtra = moment(edate, "YYYY-MM-DD HH:mm:ss")
                    .add(that.extraTime, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss");
                that.selectedProg = {
                  id: prog.program_id,
                  chartIndex: categories[this.pos],
                  y: prog[value],
                  tvId: prog.tvid,
                  progName: prog.name,
                  progId: null,
                  sdate,
                  edate,
                  sdateExtra,
                  edateExtra,
                  short_text: ""
                };
                that.getMinutesChart();
                that.getIncomesChart();
              },
              mouseover: function() {
                this.axis.chart.series[0].onMouseOver();
              }
            }
          }
        },
        yAxis: {
          opposite: true,
          reversedStacks: false,
          title: { text: "" }
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let [title, subtitle, hhmm] = this.x.split(" | ");
            if (!hhmm) {
              hhmm = subtitle;
              subtitle = "";
            }
            const sdt = hhmm.substring(0, 19);
            const edt = hhmm.substring(22, 41);
            const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );
            const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );

            if (sdate != edate) {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    `;
            } else {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            }
            return `
              ${hhmm}<br>
              ${name}: <b>${this.y}</b>
              <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
              <b>${title}</b><br>
              ${subtitle}
            `;
          }
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
            }
          },
          series: {
            turboThreshold: Infinity,
            point: {
              events: {
                click: function() {
                  const sdate = this.prog.start_loc;
                  const edate = this.prog.end_loc;
                  const sdateExtra = moment(sdate, "YYYY-MM-DD HH:mm:ss")
                      .subtract(that.extraTime, "minutes")
                      .format("YYYY-MM-DD HH:mm:ss");
                  const edateExtra = moment(edate, "YYYY-MM-DD HH:mm:ss")
                      .add(that.extraTime, "minutes")
                      .format("YYYY-MM-DD HH:mm:ss");
                  that.selectedProg = {
                    id: this.prog.program_id,
                    chartIndex: this.x,
                    y: this.y,
                    tvId: this.prog.tvid,
                    progName: this.prog.name,
                    progId: null,
                    sdate,
                    edate,
                    sdateExtra,
                    edateExtra,
                    short_text: ""
                  };
                  that.getMinutesChart();
                  that.getIncomesChart();
                }
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            stacking: false,
            dataLabels: {
              enabled: false
            },
            pointWidth: 30
          }
        },
        series: series
      };
    },
    topCities() {
      let cities = this.cities;
      cities = cities.filter((el) => el.value > 0);
      let sumCities = 0;
      let count = 11;
      if (this.selected_geo > 0) {
        count = 10;
      }
      cities = cities.sort((a, b) => b.value - a.value);
      let result_cities = [];
      cities.forEach((el) => {
        if (--count > 0) {
          sumCities += Number(el.value);
          result_cities.push(el);
        }
      });
      cities = result_cities;
      if (sumCities < 1 && this.selected_geo > 0) {
        cities.push({
          type: "другие города",
          value: (1 - sumCities).toFixed(2)
        });
      }
      cities = cities.sort((a, b) => b.value - a.value);

      const channelsNum = cities.length;

      let series = [
        {
          data: cities.map((el) => {
            return { y: Number(el.value) * 100 };
          }),
          name: "Доля"
        }
      ];

      const chartMax = Math.max(...series[0].data.map((s) => s.y));
      let colors = new Array(channelsNum).fill(CONSTS.TOP20_CHANNELS_COLORS);

      return {
        chart: {
          type: "column",
          events: {},
          height: this.heightChart
        },
        title: { text: "" },
        colors,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories: cities.map((el) => el.type),
          labels: {
            formatter: function() {
              let str = this.value;
              return str;
            },
            rotation: -60
          }
        },
        yAxis: {
          min: 0,
          max: chartMax,
          reversedStacks: false,
          title: { text: "Доля" },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080"
            }
          ]
        },
        plotOptions: {
          column: {
            colorByPoint: true
          },
          bar: {
            dataLabels: {
              enabled: true
            }
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            point: {
              events: {
                click: function() {
                  // that.channelIndex = this.index;
                  // that.selectedTVId = that.tvs[this.index].id;
                  // window.scrollTo(0, top);
                  // document
                  //     .getElementById("hminChart")
                  //     .scrollIntoView({ behavior: "smooth" });
                }
              }
            },
            stacking: false,
            dataLabels: { enabled: false }
          }
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>Доля:</b></br>{point.y:.2 f}%"
        },
        //'value:.3 f'
        series: series
      };
    },

    cityTypes() {
      let mobData = this.city_types
          .filter((el) => el.value > 0)
          .sort((a, b) => b.value - a.value);
      let sumCities = 0;
      mobData.forEach((el) => {
        sumCities += Number(el.value);
      });
      if (sumCities < 1) {
        mobData.push({
          type: "прочее",
          value: (1 - sumCities).toFixed(2)
        });
      }
      const series = [
        {
          data: mobData.map((el) => {
            return { y: Number(el.value) };
          }),
          name: "Доля",
          color: "#1976D2"
        },
        {
          data: mobData.map((el) => {
            return { y: 1 - Number(el.value) };
          }),
          name: "Доля",
          color: "rgba(0, 0, 0, 0.12)"
        }
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = mobData.map((el) => el.type);
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (5 + 18) + 60
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: 0,
            y: -8,
            style: {
              color: "rgba(0, 0, 0, 0.6);",
              fontSize: "12px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "20px"
            },
            formatter: function() {
              const data = this.value.split("|");
              const index = this.axis.categories.indexOf(this.value);
              let percent = "";
              if (index > -1) {
                console.log();
                percent = series[0].data[index].y * 100;
                percent = percent.toFixed(0);
              }
              return `${data[0]}, ${percent} %`;
            },
            events: {
              mouseover: function() {
                this.axis.chart.series[0].onMouseOver();
              }
            }
          }
        },
        yAxis: {
          gridLineWidth: 0,
          opposite: true,
          reversedStacks: false,
          labels: {
            visible: false,
            enabled: false
          },
          title: { text: "", visible: false }
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let [title, subtitle, hhmm] = this.x.split(" | ");
            if (!hhmm) {
              hhmm = subtitle;
              subtitle = "";
            }
            const sdt = hhmm.substring(0, 19);
            const edt = hhmm.substring(22, 41);
            const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );
            const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );

            if (sdate != edate) {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    `;
            } else {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            }
            return `
              ${hhmm}<br>
              Доля: <b>${this.y}</b>
              <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
              <b>${title}</b><br>
              ${subtitle}
            `;
          }
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
            },
            stacking: "percent"
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            stacking: false,
            dataLabels: {
              enabled: false
            },
            pointWidth: 1
          }
        },
        series: series
      };
    },

    smartphonesShare() {
      let mobData = this.devices.mobile
          .filter((el) => el.value > 0)
          .sort((a, b) => b.value - a.value);
      const series = [
        {
          data: mobData.map((el) => {
            return { y: Number(el.value) };
          }),
          name: "Доля",
          color: "#1976D2"
        },
        {
          data: mobData.map((el) => {
            return { y: 1 - Number(el.value) };
          }),
          name: "Доля",
          color: "rgba(0, 0, 0, 0.12)"
        }
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = mobData.map((el) => el.type);
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (5 + 18) + 120
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: 0,
            y: -8,
            style: {
              color: "rgba(0, 0, 0, 0.6);",
              fontSize: "12px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "20px"
            },
            formatter: function() {
              const data = this.value.split("|");
              const index = this.axis.categories.indexOf(this.value);
              let percent = "";
              if (index > -1) {
                console.log();
                percent = series[0].data[index].y * 100;
                percent = percent.toFixed(0);
              }
              return `${data[0]}, ${percent} %`;
            },
            events: {
              mouseover: function() {
                this.axis.chart.series[0].onMouseOver();
              }
            }
          }
        },
        yAxis: {
          gridLineWidth: 0,
          opposite: true,
          reversedStacks: false,
          labels: {
            visible: false,
            enabled: false
          },
          title: { text: "", visible: false }
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let [title, subtitle, hhmm] = this.x.split(" | ");
            if (!hhmm) {
              hhmm = subtitle;
              subtitle = "";
            }
            const sdt = hhmm.substring(0, 19);
            const edt = hhmm.substring(22, 41);
            const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );
            const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );

            if (sdate != edate) {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    `;
            } else {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            }
            return `
              ${hhmm}<br>
              Доля: <b>${this.y}</b>
              <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
              <b>${title}</b><br>
              ${subtitle}
            `;
          }
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
            },
            stacking: "percent"
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            stacking: false,
            dataLabels: {
              enabled: false
            },
            pointWidth: 1
          }
        },
        series: series
      };
    },
    tabletsShare() {
      let mobData = this.devices.tablet
          .filter((el) => el.value > 0)
          .sort((a, b) => b.value - a.value);
      const series = [
        {
          data: mobData.map((el) => {
            return { y: Number(el.value) };
          }),
          name: "Доля",
          color: "#1976D2"
        },
        {
          data: mobData.map((el) => {
            return { y: 1 - Number(el.value) };
          }),
          name: "Доля",
          color: "rgba(0, 0, 0, 0.12)"
        }
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = mobData.map((el) => el.type);
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (5 + 18) + 120
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: 0,
            y: -8,
            style: {
              color: "rgba(0, 0, 0, 0.6);",
              fontSize: "12px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "20px"
            },
            formatter: function() {
              const data = this.value.split("|");
              const index = this.axis.categories.indexOf(this.value);
              let percent = "";
              if (index > -1) {
                console.log();
                percent = series[0].data[index].y * 100;
                percent = percent.toFixed(0);
              }
              return `${data[0]}, ${percent} %`;
            },
            events: {
              mouseover: function() {
                this.axis.chart.series[0].onMouseOver();
              }
            }
          }
        },
        yAxis: {
          gridLineWidth: 0,
          opposite: true,
          reversedStacks: false,
          labels: {
            visible: false,
            enabled: false
          },
          title: { text: "", visible: false }
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let [title, subtitle, hhmm] = this.x.split(" | ");
            if (!hhmm) {
              hhmm = subtitle;
              subtitle = "";
            }
            const sdt = hhmm.substring(0, 19);
            const edt = hhmm.substring(22, 41);
            const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );
            const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY"
            );

            if (sdate != edate) {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    `;
            } else {
              hhmm = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
              )}
                    - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            }
            return `
              ${hhmm}<br>
              Доля: <b>${this.y}</b>
              <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
              <b>${title}</b><br>
              ${subtitle}
            `;
          }
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false
            },
            stacking: "percent"
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            stacking: false,
            dataLabels: {
              enabled: false
            },
            pointWidth: 1
          }
        },
        series: series
      };
    },

    drawTileMapData() {
      return this.options.tvRegions;
    },
    drawChartData() {
      return this.mapData;
    },
    drawChartOptionsTiles() {
      const that = this;
      ////console.log(this.mapData)
      return {
        chart: {
          type: "tilemap",
          inverted: true,
          height: "50%"
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        title: {
          text: ""
        },
        xAxis: {
          visible: false
        },

        yAxis: {
          visible: false
        },

        colorAxis: {
          min: 0,
          max: 1,
          stops: this.mapChartColors,
          visible: false
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>{point.region}</b></br>{point.orig_value:.3 f}"
        },
        plotOptions: {
          tilemap: {
            cursor: "pointer",
            point: {
              events: {
                click: function(event) {
                  that.selected_geo = event.point.region_id;
                  console.log(that.selected_geo);
                  that.getNews();
                }
              }
            },
            pointPadding: 0,
            tileShape: "square",
            dataLabels: {
              enabled: true,
              format: "{point.short}",
              style: {
                textOutline: false
              }
            }
          }
        },
        series: [
          {
            name: "",
            data: that.drawTileMapData
          }
        ]
      };
    },
    drawChartOptionsMap() {
      const that = this;
     // console.log(this.mapData)
      return {
        chart: {
          height: "50%"
        },
        title: {
          text: "  "
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          headerFormat: "",
          formatter: function() {
            const region = that.options.tvRegions.find(
                (region) => region.hc_key === this.point.properties["hc-key"]
            );

            return `<b>${region.region}</b><br /> ${region.value.toFixed(2)}`;
          },
          // pointFormat: "<b>{point.region}</b></br>{point.value:.3 f}",
          // pointFormat: "{point.value:.3 f}",
          shared: true
        },
        plotOptions: {
          series: {
            events: {
              click: function(event) {
                let hc_key;
                if (event.point.properties)
                  hc_key = event.point.properties["hc-key"];
                else if (event.target.point)
                  hc_key = event.target.point["hc-key"];

                const region = that.options.tvRegions.find(
                    (el) => el.hc_key === hc_key
                );
                that.selected_geo = region.region_id;
                that.getNews();
              }
            },

            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },
        colorAxis: {
          min: 0,
          max: 1,
          stops: this.mapChartColors,
          visible: false
        },

        series: [
          {
            // Use the gb-all map with no data as a basemap
            mapData: mapMarkup,
            data: that.mapData,
            borderColor: "#FFFFFF",
            nullColor: "rgba(220,220,220, 1)",
            showInLegend: false,
            cursor: "pointer",
            name: "Доля телесмотрения",
            dataLabels: {
              enabled: true,
              formatter: function() {
                let point = this.point.properties["hc-key"];
                if (that.drawTileMapData.length > 0) {
                  const str = that.drawTileMapData.find(
                    (region) => region.hc_key === point
                  ).short;
                  return str;
                }
                return "";
                //
              }
            }
          }
        ]
      };
    },
    disableField() {
      return false;
    }
  },
  async mounted() {
    console.log(this.pageType);

    if (!this.hasPriv) {
      this.$router.push("/");
    }
    await this.getMapData();
  },

  methods: {
    openOriginalPage(page) {
      window.open(page, "_blank").focus();
    },
    showInternetNews() {
      this.showNews = true;
      this.getInternetNewsData();
    },
    updateMap() {
      let tmp = this.options.tvRegions;
      this.options.tvRegions = tmp;
    },
    setNewsIndex(ind) {
      this.newsTimeIndex = ind;
    },
    handleResize() {
      this.contentWidth = document.getElementById("content_card").clientWidth;
      this.contentWidth = this.contentWidth - 70 - 20; // вычитаем отсупы внутри и снаружи графика
    },
    repaintBands() {
      const plotBands = [
        {
          from: 0,
          to: this.extraTime,
          color: CONSTS.CONTENT_PRODUCERS_EXTRATIME_COLOR
        },
        {
          from: this.extraTime,
          to: this.chartHour.charts[0].categories.length - this.extraTime,
          color: CONSTS.CONTENT_PRODUCERS_SHOW_COLOR
        },
        {
          from: this.chartHour.charts[0].categories.length - this.extraTime,
          to: this.chartHour.charts[0].categories.length - 1,
          color: CONSTS.CONTENT_PRODUCERS_EXTRATIME_COLOR
        }
      ];

      if (this.$refs.chartHour && this.$refs.chartHour.chart) {
        this.$refs.chartHour.chart.xAxis[0].update({ plotBands });
      }
      if (this.$refs.chartIncomes && this.$refs.chartIncomes.chart) {
        this.$refs.chartIncomes.chart.xAxis[0].update({ plotBands });
      }
    },
    prepareValuesData(categories, series, series2 = null) {
      const values = {};
      for (let i = 0; i < categories.length; i++) {
        const key = moment(categories[i]).utc().format("YYYY-MM-DD HH:mm");
        const newValues = [series.data[i].y];
        if (series2) {
          // приходы-уходы - 2 значения
          newValues.push(series2.data[i].y);
        }
        values[key] = newValues;
      }
      return values;
    },
    playPoint(videoChannelId, dt, channelName, valueName, programs, values) {
      console.log(
          `Play: ${videoChannelId} (${channelName}) time: ${dt} ${this.videoPlayerOn}`
      );

      if (this.videoPlayerOn) {
        this.videoPlayerOn = false;
        return setTimeout(() =>
            this.playPoint(
                videoChannelId,
                dt,
                channelName,
                valueName,
                programs,
                values
            )
        );
      }
      this.videoPlayerChannel = videoChannelId;
      this.videoPlayerChannelName = channelName;
      this.videoPlayerValueName = valueName;
      this.videoPlayerDt = dt;
      this.videoPlayerProgram = programs;
      this.videoPlayerValues = values;
      this.videoPlayerOn = true;
    },
    toggleAverageValues() {
      this.showAvarages = !this.showAvarages;
    },
    getTvIdColor(tvId) {
      return CONSTS.TV_SPECIAL_COLORS[tvId][0];
    },
    async saveChartToPng() {
      const canvas = await html2canvas(
          document.querySelector(`#highcharts_png`)
      );
      Utils.saveAs(canvas.toDataURL(), `${this.filename}.png`);
    },
    async getMinutesChart() {
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      const specificReportProperties = {
        requestedReport: "content_producers",
        message: "Производителям контента" // если задан - пишем под названием отчета
      };
      const reg = this.options.tvRegionsPreData.find(
          (el) => el.region_id == this.selected_geo
      );
      const reqData = new FormData();
      reqData.append("geo", reg.mh_city_id ?? 495);
      //this.selectedProg.sdate="2022-09-30 23:00:00"
      reqData.append("requestedReport", "tvchannels");
      reqData.append("specificReport", true);
      reqData.append(
          "specificReportProperties",
          JSON.stringify(specificReportProperties)
      );
      reqData.append("helper", "mh");
      reqData.append("manytv", false);
      reqData.append("source_id", 1);
      reqData.append("detalisation", 1);
      reqData.append("aggregate", "Day");
      reqData.append("report_type", "hour");
      reqData.append("indicator", this.indicator);
      reqData.append("tv", this.selectedProg.tvId);
      reqData.append(
          "startDate",
          this.selectedProg.sdateExtra.substring(0, 10)
      );
      reqData.append("endDate", this.selectedProg.edateExtra.substring(0, 10));
      reqData.append(
          "startTime",
          this.selectedProg.sdateExtra.substring(11, 14) + "00"
      );
      reqData.append("endTime", this.selectedProg.edateExtra.substring(11, 16));
      reqData.append("through", true);
      reqData.append("weekdays", "1,2,3,4,5,6,7");
      const res = await axios
          .post("reports/cubedata", reqData, {})
          .catch(function() {
            this.chart_loading = null;
            this.isStoped = true;
          });

      if (this.isStoped) return;

      if (res.data.charts.length > 1) {
        // объединяем данные при переходе через сутки
        const chartsCategories = res.data.charts.map(
            (chart) => chart.categories
        );
        res.data.charts[0].categories = [].concat(...chartsCategories);
        const chartsSeries = res.data.charts.map(
            (chart) => chart.series[0].data
        );
        res.data.charts[0].series[0].data = [].concat(...chartsSeries);
      }

      let startIndex = 0; // ищем где начинается передача т.к. на входе данные по часу
      let stopIndex = 0; // ищем где заканчивается передача т.к. на входе данные по часу

      const startMinute = +this.selectedProg.sdateExtra.substring(14, 16); // первая минута передачи
      const stopHHMM = this.selectedProg.edateExtra.substring(11, 16); // последняя минута передачи
      for (const item of res.data.charts[0].categories) {
        const catMinute = +item.substring(3, 5);
        if (catMinute >= startMinute) break;
        startIndex++;
      }
      for (let i = res.data.charts[0].categories.length - 1; i > 0; i--) {
        const hhmm = res.data.charts[0].categories[i];
        if (hhmm <= stopHHMM) {
          stopIndex = i;
          break;
        }
      }
      res.data.charts[0].series[0].data = res.data.charts[0].series[0].data
          .filter((item, i) => i >= startIndex)
          .filter((item, i) => i <= stopIndex - startIndex);
      res.data.charts[0].categories = res.data.charts[0].categories
          .filter((item, i) => i >= startIndex)
          .filter((item, i) => i <= stopIndex - startIndex)
          .map(
              (item) =>
                  moment(
                      `${res.data.charts[0].series[0].subtitle} ${item}:00Z`,
                      "DD.MM.YYYY HH:mm:ssZ"
                  ).unix() * 1000
          );

      this.chartHour = res.data;
      this.chart2ready = true;
      setTimeout(() => this.repaintBands());
    },

    async getIncomesChart() {
      this.chart3ready = false;
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      const specificReportProperties = {
        requestedReport: "content_producers",
        message: "Производителям контента" // если задан - пишем под названием отчета
      };
      const reg = this.options.tvRegionsPreData.find(
          (el) => el.region_id == this.selected_geo
      );
      const reqData = new FormData();
      reqData.append("geo", reg.mh_city_id ?? 495);
      reqData.append("requestedReport", "incomes");
      reqData.append("specificReport", true);
      reqData.append(
          "specificReportProperties",
          JSON.stringify(specificReportProperties)
      );
      reqData.append("helper", "mh");
      reqData.append("manytv", false);
      reqData.append("source_id", 1);
      reqData.append("detalisation", 1);
      reqData.append("aggregate", "Period");
      reqData.append("report_type", "period");
      reqData.append("indicator", "IncomesOutcomes");
      reqData.append("tv", this.selectedProg.tvId);
      // reqData.append("programIds", this.selectedProg.progId);
      reqData.append(
          "startDate",
          this.selectedProg.sdateExtra.substring(0, 10)
      );
      reqData.append("endDate", this.selectedProg.edateExtra.substring(0, 10));
      reqData.append(
          "startTime",
          this.selectedProg.sdateExtra.substring(11, 14) + "00"
      );
      reqData.append("endTime", this.selectedProg.edateExtra.substring(11, 16));
      //      reqData.append("startTime", "00:00");
      //      reqData.append("endTime", "23:59");
      reqData.append("through", true);
      reqData.append("weekdays", "1,2,3,4,5,6,7");
      const res = await axios
          .post("reports/cubedata", reqData, {})
          .catch(function() {
            this.chart_loading = null;
            this.isStoped = true;
          });

      if (this.isStoped) return;

      res.data.charts[0].categories = res.data.charts[0].categories.map(
          (item) => moment(`${item}:00Z`, "DD.MM.YYYY HH:mm:ssZ").unix() * 1000
      );

      res.data.charts[0].categories = res.data.charts[0].categories.map(
          (item) => moment(item).utc().format("YYYY-MM-DD HH:mm:ss")
      );

      let startIndex = 0; // ищем где начинается передача т.к. на входе данные по часу
      let stopIndex = 0; // ищем где заканчивается передача т.к. на входе данные по часу
      for (const item of res.data.charts[0].categories) {
        if (item >= this.selectedProg.sdateExtra) break;
        startIndex++;
      }
      for (let i = res.data.charts[0].categories.length - 1; i > 0; i--) {
        if (res.data.charts[0].categories[i] <= this.selectedProg.edateExtra) {
          stopIndex = i;
          break;
        }
      }

      res.data.charts[0].series[0].data = res.data.charts[0].series[0].data
          .filter((item, i) => i >= startIndex)
          .filter((item, i) => i <= stopIndex - startIndex);
      res.data.charts[0].series[1].data = res.data.charts[0].series[1].data
          .filter((item, i) => i >= startIndex)
          .filter((item, i) => i <= stopIndex - startIndex);
      res.data.charts[0].categories = res.data.charts[0].categories
          .filter((item, i) => i >= startIndex)
          .filter((item, i) => i <= stopIndex - startIndex);

      this.chartIncomes = res.data;
      this.chart3ready = true;
      setTimeout(() => this.repaintBands());
    },
    async getReport(data) {
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      const specificReportProperties = {
        requestedReport: "content_producers", // id оригинального отчета
        title: "Производителям контента", // если задан - пишем вместо названия отчета
        //  message: "Производителям контента", // если задан - пишем под названием отчета
        url: "content_producers", //если задан - редиректим по клику на эту страницу
        hidden: true //если задан и true - не показываем в истории
      };

      this.chart = null;
      this.chartProg = null;
      this.tvIds = [];
      this.reportResults = {};
      this.programsColors = {};
      this.selectedProg = null;
      this.chart2ready = false;
      this.chart3ready = false;
      this.indicator = data.indicator;
      this.range = data.range;
      this.myShows = data.myShows;

      const that = this;
      const tvIdShows = {};
      for (const show of data.shows) {
        if (!(show.index_tv_id_child in tvIdShows))
          tvIdShows[show.index_tv_id_child] = [];
        tvIdShows[show.index_tv_id_child].push(+show.program_id);
      }
      for (const tvId in tvIdShows) {
        this.chart_loading = true;
        const CancelToken = axios.CancelToken;
        const reqData = new FormData();
        reqData.append("requestedReport", "tvshows");
        reqData.append("noQueue", true);
        reqData.append("specificReport", true);
        reqData.append(
            "specificReportProperties",
            JSON.stringify(specificReportProperties)
        );
        reqData.append("geo", 178);
        reqData.append("helper", "mh");
        reqData.append("manytv", false);
        reqData.append("source_id", 1);
        reqData.append("report_type", "period");
        reqData.append("useCoeff", "no");
        reqData.append("aggregate", "None");
        reqData.append("detalisation", 60);
        reqData.append("indicator", data.indicator);
        reqData.append("startDate", this.range.startDate);
        reqData.append("endDate", this.range.endDate);
        reqData.append("startTime", this.range.startTime);
        reqData.append("endTime", this.range.endTime);
        reqData.append("through", this.range.through);
        reqData.append("weekdays", this.range.weekdays);
        reqData.append("tv", tvId);
        reqData.append("programIds", tvIdShows[tvId].join(","));
        let res;
        try {
          res = await axios.post("reports/cubedata", reqData, {
            cancelToken: new CancelToken(function executor(c) {
              that.axiosCancel = c;
            })
          });
        } catch (e) {
          this.chart_loading = null;
          this.isStoped = true;
        }
        if (this.isStoped) break;
        //this.reportResults[tvId] = res.data;
        if (res.data.charts[0].categories.length) {
          this.tvIds.push(tvId);
          this.tvNames[tvId] = res.data.charts[0].series[0].subtitle;
          this.channelsColors[tvId] =
              CONSTS.CHART_COLORS[this.tvIds.length - 1];
          if (tvId in CONSTS.TV_SPECIAL_COLORS)
            this.channelsColors[tvId] = CONSTS.TV_SPECIAL_COLORS[tvId][0];
          this.appendChartData(
              res.data.charts[0],
              tvId,
              res.data.charts[0].series[0].subtitle
          );
        }
      }

      const colors = [...CONSTS.CHART_COLORS];
      for (const tvShow of this.chart.programs) {
        if (!(tvShow.program_id in this.programsColors)) {
          if (colors.length) {
            this.programsColors[tvShow.program_id] = colors.shift();
          } else {
            this.programsColors[tvShow.program_id] = CONSTS.CHART_COLORS[0];
          }
        }
      }
      //console.log(this.programsColors);
      this.chart_loading = false;
      let seriesTotal = 0;
      this.avgValue = 0;
      if (!this.chart.series[0].data.length) return;
      for (let i = 0; i < this.chart.series[0].data.length; i++) {
        seriesTotal += this.chart.series[0].data[i].y;
      }
      this.avgValue = seriesTotal / this.chart.series[0].data.length;

      this.chart.categoriesDays = this.getCategoriesDaysMarks(
          this.chart.categories
      );
      this.chartProg = JSON.parse(JSON.stringify(this.chart));
      this.handleResize();

      const chartMax = Math.max(
          ...this.chartProg.series[0].data.map((s) => s.y)
      );

      const showsNum = this.chartProg.series[0].data.length;
      //const bgWidth = ((this.contentWidth - 50) / showsNum) | 0;
      //let pointWidth = (bgWidth/1.6)|0;

      const bgSeries = new Array(showsNum)
          .fill()
          .map(() => ({ y: chartMax * 2, color: "rgba(0,0,0,0)" }));

      this.chartProg.series.unshift({
        // pointWidth: bgWidth,
        enableMouseTracking: false,
        showInLegend: false,
        grouping: false,
        data: bgSeries
      });
    },
    appendChartData(data, tvId, tvName) {
      if (this.chart === null) {
        this.chart = data;
        this.chart.channels = [];
        for (let i = 0; i < data.categories.length; i++) {
          this.chart.channels.push(tvId);
          // цвета колонок по названиям каналов - возможно веренем!!
          //this.chart.series[0].data[i].color = this.channelsColors[tvId];
          this.chart.series[0].data[i].tvId = tvId;
          this.chart.series[0].data[i].tvName = tvName;
        }
        return;
      }

      for (let itemIndex = 0; itemIndex < data.categories.length; itemIndex++) {
        const dt = this.getDtFromCategoryName(data.categories[itemIndex]);
        for (
            let chartIndex = 0;
            chartIndex < this.chart.categories.length;
            chartIndex++
        ) {
          const chartDt = this.getDtFromCategoryName(
              this.chart.categories[chartIndex]
          );
          let chartDtNext = "9999-99-99 99:99:99";
          if (chartIndex < this.chart.categories.length - 1) {
            chartDtNext = this.getDtFromCategoryName(
                this.chart.categories[chartIndex + 1]
            );
          }
          if (dt >= chartDt && dt < chartDtNext) {
            // цвета колонок по названиям каналов - возможно веренем!!
            // data.series[0].data[itemIndex].color = this.channelsColors[tvId];
            data.series[0].data[itemIndex].tvName = tvName;
            data.series[0].data[itemIndex].tvId = tvId;
            this.chart.programs.splice(
                chartIndex + 1,
                0,
                data.programs[itemIndex]
            );
            this.chart.categories.splice(
                chartIndex + 1,
                0,
                data.categories[itemIndex]
            );
            this.chart.channels.splice(chartIndex + 1, 0, tvId);
            this.chart.series[0].data.splice(
                chartIndex + 1,
                0,
                data.series[0].data[itemIndex]
            );
            break;
          }
          if (dt < chartDt) {
            // цвета колонок по названиям каналов - возможно веренем!!
            //data.series[0].data[itemIndex].color = this.channelsColors[tvId];
            data.series[0].data[itemIndex].tvName = tvName;
            data.series[0].data[itemIndex].tvId = tvId;
            this.chart.programs.unshift(data.programs[itemIndex]);
            this.chart.categories.unshift(data.categories[itemIndex]);
            this.chart.channels.unshift(tvId);
            this.chart.series[0].data.unshift(data.series[0].data[itemIndex]);
            break;
          }
        }
      }
    },
    getTotalDays(categories) {
      let oldDt;
      let count = 0;
      for (const category of categories) {
        const dt = this.getDtFromCategoryName(category).substring(0, 10);
        if (dt != oldDt && dt >= this.range.startDate) {
          count++;
          oldDt = dt;
        }
      }
      return count;
    },
    getCategoriesDaysMarks(categories) {
      const daysCount = this.getTotalDays(categories);
      console.log(daysCount);
      let oldDt;
      const posData = [];
      let daysStep = 1;
      // if (daysCount > 7) daysStep = 2;

      let dayCount = 0;
      for (const category of categories) {
        const dt = this.getDtFromCategoryName(category).substring(0, 10);
        let newValue = null;
        if (dt != oldDt && dt >= this.range.startDate) {
          dayCount++;
          oldDt = dt;
          if (dayCount >= daysStep) {
            newValue = dt;
            dayCount = 0;
          }
        }
        posData.push(newValue);
      }
      return posData;
    },
    getDtFromCategoryName(str) {
      let [prog, subprog, dt] = str.split("|");
      if (!dt) {
        // костыль с разбором формата данных
        dt = subprog;
        subprog = "";
        prog;
      }
      return dt.substring(1, 20);
    },
    drawProgDates(prog) {
      const sdt = prog.sdate;
      const edt = prog.edate;

      const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
      const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");

      if (sdate != edate) {
        return `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")}
               - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
            "DD.MM.YYYY HH:mm"
        )}
              `;
      }

      return `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")}
              - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
    },
    makeRegionStr() {
      if (this.selected_geo > 0) {
        return this.options.tvRegionsPreData.find(
            (el) => el.region_id == this.selected_geo
        ).region;
      }
      return "";
    },
    initSlider() {
      this.ranges = [-30, -11, 11, 30];
      const slider = document.getElementById("comparison_slider");
      noUiSlider.create(slider, {
        start: CONSTS.TV_COMPARE_SLIDER_RANGES,
        margin: 1,
        connect: [true, true, true, true, true],
        orientation: "horizontal",
        behaviour: "tap-drag",
        step: 1,
        tooltips: true,
        format: wNumb({
          decimals: 0,
          suffix: "%",
          edit: function(value) {
            if (parseInt(value) > 0) {
              value = "+" + value;
            }
            return value;
          }
        }),
        range: {
          min: -100,
          max: 100
        }
      });
      var connect = slider.querySelectorAll(".noUi-connect");
      var classes = [
        "c-1-color",
        "c-2-color",
        "c-3-color",
        "c-4-color",
        "c-5-color"
      ];

      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }
      const that = this;
      slider.noUiSlider.on("update", (values) => {
        values.forEach((item, i) => {
          this.ranges[i] = parseInt(item);
        });
        this.$forceUpdate();
        that.$forceUpdate();
        // that.$refs.tileMap.chart.colorAxis.stops=  [
        //   [0, "rgba(205, 205, 205, 0.5)"],
        //   [1+this.ranges[0]/100, "rgba(241, 0, 0, 0.5)"],
        //   [1+this.ranges[1]/100, "rgba(245, 135, 60, 0.5)"],
        //   [1, "rgba(251, 227, 149, 0.5)"],
        //   [1+this.ranges[2]/100, "rgba(185, 210, 68, 0.5)"],
        //   [1+this.ranges[3]/100, "rgba(54, 175, 75, 0.5)"],
        // ]
        // that.$refs.defMap.chart.colorAxis.stops=  [
        //   [0, "rgba(205, 205, 205, 0.5)"],
        //   [1+this.ranges[0]/100, "rgba(241, 0, 0, 0.5)"],
        //   [1+this.ranges[1]/100, "rgba(245, 135, 60, 0.5)"],
        //   [1, "rgba(251, 227, 149, 0.5)"],
        //   [1+this.ranges[2]/100, "rgba(185, 210, 68, 0.5)"],
        //   [1+this.ranges[3]/100, "rgba(54, 175, 75, 0.5)"],
        // ]
      });
    },
    async hideTip() {
      await this.$store.dispatch("saveSettings", {
        compare_period_tip_closed: 1
      });
    },
    toExcel() {
    },
    // eslint-disable-next-line no-unused-vars
    async getNewsData() {
      const reg = this.options.tvRegionsPreData.find(
          (el) => el.region_id == this.selected_geo
      );
      const req = new FormData();
      req.append("geo", reg.mh_city_id ?? 495);
      req.append("period", this.periodStr);
      req.append("time", this.newsTimeStr);
      req.append("type", this.pageType);
      this.news_data = [];
      let res = await axios.post("special/tvNews", req);
      if (res && res.data) {
        this.news_data = res.data;
        this.channelNameStr = "";
        if (res.data.length && res.data[0].tv_name) {
          console.log(res.data[0].tv_name)
          this.channelNameStr = `(${res.data[0].tv_name})`;
        }  
      }
    },

    async getInternetNewsData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      this.internet_news_data = [];
      this.internet_news_loading = true;
      let res = await axios.post("special/internetNews", req);
      if (res && res.data) {
        this.internet_news_data = res.data;
        this.internet_news_loading = false;
      }
    },

    async getNewsCitiesData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      let res = await axios.post("special/internetNewsCities", req);
      this.cities = [];
      this.city_types = [];
      if (res && res.data) {
        this.cities = res.data.cities;
        this.city_types = res.data.city_types;
      }
    },
    async getTagsData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      let res = await axios.post("special/internetTags", req);
      this.tagsData = [];
      if (res && res.data) {
        this.tagsData = res.data;
      }
    },
    async getDevicesData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      let res = await axios.post("special/internetDevices", req);
      this.devices = {
        devices: [],
        mobile: [],
        tablet: []
      };
      if (res && res.data) {
        this.devices = res.data;
      }
    },
    async getMapData() {
      this.regionStr = "";
      this.channelNameStr = "";
      this.loading = true;
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("time", this.newsTimeStr);
      req.append("eq", this.toggle_type_str);
      req.append("type", this.pageType);
      let res = await axios.post("special/tv", req);
      this.mapData = [];
      this.options.tvRegions = [];
      let preData = [];

      for (let i = 0; i < this.options.tvRegionsPreData.length; i++) {
        let value = 0;
        let koeff = 0;
        let disabled = false;
        let reg = res.data.find(
            (el) => el.mh_city_id == this.options.tvRegionsPreData[i].mh_city_id
        );
        if (reg) {
          value = reg.value;
          koeff = reg.koeff;
          disabled = reg.disabled;
        }
        this.options.tvRegions.push({
          value: koeff,
          x: Number(this.options.tvRegionsPreData[i].x),
          koeff: koeff,
          orig_value: value,
          region_id: this.options.tvRegionsPreData[i].region_id,
          y: Number(this.options.tvRegionsPreData[i].y),
          hc_key: this.options.tvRegionsPreData[i].hc_key,
          short: this.options.tvRegionsPreData[i].short,
          mh_city_id: this.options.tvRegionsPreData[i].mh_city_id,
          region: this.options.tvRegionsPreData[i].region,
          dataLabels: {color: disabled?"#666666":"#000000"},
        });
        //if (this.options.tvRegions[i].hc_key != "ru-cn")
        preData.push([
          this.options.tvRegions[i].hc_key,
          this.options.tvRegions[i].koeff,
          disabled
        ]);
      }
      preData.sort();
      this.mapData = preData;
      this.loading = false;
      this.getNews();
      setTimeout(() => {
        //this.initSlider();
      });
      //console.log(this.mapData);
    },
    async getNews() {
      this.regionStr = this.makeRegionStr();
      await this.getNewsData();

      this.getMinutesChart();
      this.getIncomesChart();
    }
  },
  watch: {
    newsTimeIndex() {
      this.getMapData();
    },
    toggle_type(val) {
      console.log(val);
      this.getMapData();
    },
    toggle_news_type(val) {
      switch (val) {
        case 1:
          this.indicator = "Share";
          break;
        case 2:
          this.indicator = "TVR";
          break;
        case 3:
          this.indicator = "Reach";
          break;
        default:
          this.indicator = "Audience";
          break;
      }
      // this.selected_geo = -1;
      this.getMapData();
    },
    region_chip(val) {
      if (val == 0) {
        this.selected_geo = -1;
        this.getMapData();
      }
    },
    toggle_period() {
      this.getMapData();
    }
  },
  data() {
    return {
      showNews: false,
      internet_news_data: [],
      internet_news_loading: true,
      reportTime: null,
      avgValue: 0,
      showAvarages: true,
      contentWidth: null,
      reportResults: {},
      chart_loading: null,
      indicator: "Audience",
      range: null,
      programsColors: {}, // присвоенные передачам цвета
      extraTime: CONSTS.CONTENT_PRODUCERS_EXTRATIME, // доп время слева и справа для 2 и 3 графиков
      myShows: [], // передачи помеченные для пользователя своими
      chart: null, // первый график (накопление данных)
      chartProg: null, // первый график
      chartHour: null, // минутный график
      chartIncomes: null, // минутный график
      tvNames: {}, // соответствие tvId->tvName
      tvIds: [], // пришедшие в отчете tvId
      channelsColors: {}, // присвоенные tvId цвета
      selectedProg: null, // Данные о выбранной передаче для построенения 2-3 графиков
      chart2ready: false, // данные для 2 графика подготовлены
      chart3ready: false, // данные для 3 графика подготовлены
      videoPlayerOn: false, // если true - открыт диалог проигрывания видео
      videoPlayerChannel: null,
      videoPlayerChannelName: null,
      videoPlayerValueName: null, // название отображаемого показтеля
      videoPlayerDt: null,
      videoPlayerProgram: null, // расписание передач
      videoPlayerValues: null, // помнутные значения выбранного показателя
      regionStr: "",
      newsTimeIndex: 0,
      tickLabels: ["Все", "Утренний", "Дневной", "Вечерний"],
      loading: true,
      tagsData: [],
      news_data: [],
      selected_geo: 25,
      ranges: [-30, -11, 11, 30],
      city_types: [],
      cities: [],
      tabDevices: 0,
      tabitems: ["Смартфоны", "Планшеты"],
      devices: {
        devices: [],
        mobile: [],
        tablet: []
      },
      region_chip: true,
      mapData: [],
      options: {
        tvRegions: [],
        tvRegionsPreData: [
          {
            "region_id": "1",
            "hc_key": "ru-bl",
            "short": "Бел",
            "region": "Белгородская область",
            "y": "4",
            "x": "7",
            "mh_city_id": 224,
            "population": "1041310",
            "source": "ГТРК Белгород"
          },
          {
            "region_id": "2",
            "hc_key": "ru-br",
            "short": "Бря",
            "region": "Брянская область",
            "y": "2",
            "x": "5",
            "mh_city_id": 461,
            "population": "833197",
            "source": "ГТРК Брянск"
          },
          {
            "region_id": "3",
            "hc_key": "ru-vl",
            "short": "Влад",
            "region": "Владимирская область",
            "y": "5",
            "x": "4",
            "mh_city_id": 225,
            "population": "1049234",
            "source": "ГТРК Владимир"
          },
          {
            "region_id": "4",
            "hc_key": "ru-vr",
            "short": "Вор",
            "region": "Воронежская область",
            "y": "5",
            "x": "7",
            "mh_city_id": 188,
            "population": "1567046",
            "source": "ГТРК Воронеж"
          },
          {
            "region_id": "5",
            "hc_key": "ru-iv",
            "short": "Иван",
            "region": "Ивановская область",
            "y": "5",
            "x": "3",
            "mh_city_id": 375,
            "population": "807364",
            "source": "ГТРК Ивтерерадио"
          },
          {
            "region_id": "6",
            "hc_key": "ru-kg",
            "short": "Калу",
            "region": "Калужская область",
            "y": "2",
            "x": "4",
            "mh_city_id": 471,
            "population": "758763",
            "source": "ГТРК Калуга"
          },
          {
            "region_id": "7",
            "hc_key": "ru-kt",
            "short": "Костр",
            "region": "Костромская область",
            "y": "6",
            "x": "3",
            "mh_city_id": 246,
            "population": "459063",
            "source": "ГТРК Кострома"
          },
          {
            "region_id": "8",
            "hc_key": "ru-ks",
            "short": "Курск",
            "region": "Курская область",
            "y": "3",
            "x": "6",
            "mh_city_id": 215,
            "population": "752748",
            "source": "ГТРК Курск"
          },
          {
            "region_id": "9",
            "hc_key": "ru-lp",
            "short": "Лип",
            "region": "Липецкая область",
            "y": "4",
            "x": "6",
            "mh_city_id": 210,
            "population": "729043",
            "source": "ГТРК Липецк"
          },
          {
            "region_id": "10",
            "hc_key": "ru-2509",
            "short": "МО",
            "region": "Московская область",
            "y": "3",
            "x": "4",
            "mh_city_id": "178",
            "population": "6296406",
            "source": "Вести-Москва"
          },
          {
            "region_id": "11",
            "hc_key": "ru-ol",
            "short": "Орл",
            "region": "Орловская область",
            "y": "3",
            "x": "5",
            "mh_city_id": 412,
            "population": "483431",
            "source": "ГТРК Орел"
          },
          {
            "region_id": "12",
            "hc_key": "ru-rz",
            "short": "Ряз",
            "region": "Рязанская область",
            "y": "5",
            "x": "5",
            "mh_city_id": 206,
            "population": "793275",
            "source": "ГТРК Ока"
          },
          {
            "region_id": "13",
            "hc_key": "ru-sm",
            "short": "Смол",
            "region": "Смоленская область",
            "y": "1",
            "x": "4",
            "mh_city_id": 428,
            "population": "663281",
            "source": "ГТРК Смоленск"
          },
          {
            "region_id": "14",
            "hc_key": "ru-tb",
            "short": "Там",
            "region": "Тамбовская область",
            "y": "5",
            "x": "6",
            "mh_city_id": 242,
            "population": "611901",
            "source": "ГТРК Тамбов"
          },
          {
            "region_id": "15",
            "hc_key": "ru-tv",
            "short": "Твер",
            "region": "Тверская область",
            "y": "3",
            "x": "3",
            "mh_city_id": 220,
            "population": "950071",
            "source": "ГТРК Тверь"
          },
          {
            "region_id": "16",
            "hc_key": "ru-tl",
            "short": "Туо",
            "region": "Тульская область",
            "y": "4",
            "x": "5",
            "mh_city_id": 212,
            "population": "1082882",
            "source": "ГТРК Тула"
          },
          {
            "region_id": "17",
            "hc_key": "ru-ys",
            "short": "Яро",
            "region": "Ярославская область",
            "y": "4",
            "x": "3",
            "mh_city_id": 199,
            "population": "1011966",
            "source": "ГТРК Ярославия"
          },
          {
            "region_id": "18",
            "hc_key": "ru-ms",
            "short": "Мск",
            "region": "Город федерального значения Москва",
            "y": "4",
            "x": "4",
            "mh_city_id": 178,
            "population": "12455682",
            "source": "Вести-Москва"
          },
          {
            "region_id": "19",
            "hc_key": "ru-ki",
            "short": "Кар",
            "region": "Республика Карелия",
            "y": "3",
            "x": "1",
            "mh_city_id": 245,
            "population": "494545",
            "source": "ГТРК Карелия"
          },
          {
            "region_id": "20",
            "hc_key": "ru-ko",
            "short": "Коми",
            "region": "Республика Коми",
            "y": "9",
            "x": "2",
            "mh_city_id": 513,
            "population": "637072",
            "source": "ГТРК Коми Гор"
          },
          {
            "region_id": "21",
            "hc_key": "ru-ar",
            "short": "Арх",
            "region": "Архангельская область",
            "y": "8",
            "x": "2",
            "mh_city_id": 458,
            "population": "888896",
            "source": "ГТРК Поморье"
          },
          {
            "region_id": "22",
            "hc_key": "ru-vo",
            "short": "Волог",
            "region": "Вологодская область",
            "y": "4",
            "x": "2",
            "mh_city_id": 463,
            "population": "837220",
            "source": "ГТРК Вологда"
          },
          {
            "region_id": "23",
            "hc_key": "ru-kn",
            "short": "Кали",
            "region": "Калининградская область",
            "y": "0",
            "x": "2",
            "mh_city_id": 378,
            "population": "792070",
            "source": "ГТРК Калининград"
          },
          {
            "region_id": "24",
            "hc_key": "ru-ln",
            "short": "Лен",
            "region": "Ленинградская область",
            "y": "2",
            "x": "2",
            "mh_city_id": "126",
            "population": "1274208",
            "source": "ГТРК Санкт-Петербург"
          },
          {
            "region_id": "25",
            "hc_key": "ru-mm",
            "short": "Мур",
            "region": "Мурманская область",
            "y": "3",
            "x": "0",
            "mh_city_id": 495,
            "population": "675190",
            "source": "ГТРК Мурман"
          },
          {
            "region_id": "26",
            "hc_key": "ru-ng",
            "short": "Новг",
            "region": "Новгородская область",
            "y": "3",
            "x": "2",
            "mh_city_id": 264,
            "population": "424638",
            "source": "ГТРК Славия"
          },
          {
            "region_id": "27",
            "hc_key": "ru-ps",
            "short": "Пск",
            "region": "Псковская область",
            "y": "2",
            "x": "3",
            "mh_city_id": 418,
            "population": "440010",
            "source": "ГТРК Псков"
          },
          {
            "region_id": "28",
            "hc_key": "ru-sp",
            "short": "Спб",
            "region": "Город федерального значения Санкт-Петербург",
            "y": "2",
            "x": "1",
            "mh_city_id": 126,
            "population": "5384342",
            "source": "ГТРК Санкт-Петербург"
          },
          {
            "region_id": "29",
            "hc_key": "ru-nn",
            "short": "Нен",
            "region": "Ненецкий автономный округ",
            "y": "9",
            "x": "1",
            "mh_city_id": 496,
            "population": "32948",
            "source": ""
          },
          {
            "region_id": "30",
            "hc_key": "ru-ad",
            "short": "Адыг",
            "region": "Республика Адыгея",
            "y": "3",
            "x": "8",
            "mh_city_id": 988,
            "population": "217846",
            "source": "ГТРК Адыгея"
          },
          {
            "region_id": "31",
            "hc_key": "ru-kl",
            "short": "Калм",
            "region": "Республика Калмыкия",
            "y": "6",
            "x": "8",
            "mh_city_id": 992,
            "population": "124616",
            "source": "ГТРК Калмыкия"
          },
          {
            "region_id": "32",
            "hc_key": "ru-kr",
            "short": "Крым",
            "region": "Республика Крым",
            "y": "2",
            "x": "8",
            "mh_city_id": 230,
            "population": "966232",
            "source": "ГТРК Таврида"
          },
          {
            "region_id": "33",
            "hc_key": "ru-kd",
            "short": "Крд",
            "region": "Краснодарский край",
            "y": "4",
            "x": "8",
            "mh_city_id": 190,
            "population": "3159354",
            "source": "ГТРК Кубань"
          },
          {
            "region_id": "34",
            "hc_key": "ru-as",
            "short": "Астр",
            "region": "Астраханская область",
            "y": "7",
            "x": "8",
            "mh_city_id": 207,
            "population": "664210",
            "source": "ГТРК Лотос"
          },
          {
            "region_id": "35",
            "hc_key": "ru-vg",
            "short": "Волг",
            "region": "Волгоградская область",
            "y": "6",
            "x": "7",
            "mh_city_id": 189,
            "population": "1915787",
            "source": "ГТРК Волгоград-ТРВ"
          },
          {
            "region_id": "36",
            "hc_key": "ru-ro",
            "short": "Рост",
            "region": "Ростовская область",
            "y": "5",
            "x": "8",
            "mh_city_id": 185,
            "population": "2850790",
            "source": "ГТРК Дон-ТР"
          },
          {
            "region_id": "37",
            "hc_key": "ru-sc",
            "short": "Сев",
            "region": "город федерального значения Севастополь",
            "y": "2",
            "x": "9",
            "mh_city_id": 129,
            "population": "479394",
            "source": "ГТРК Севастополь"
          },
          {
            "region_id": "38",
            "hc_key": "ru-da",
            "short": "Даг",
            "region": "Республика Дагестан",
            "y": "7",
            "x": "9",
            "mh_city_id": 996,
            "population": "1419922",
            "source": "ГТРК Дагестан"
          },
          {
            "region_id": "39",
            "hc_key": "ru-in",
            "short": "Инг",
            "region": "Республика Ингушетия",
            "y": "6",
            "x": "10",
            "mh_city_id": 997,
            "population": "287222",
            "source": "ГТРК Ингушетия"
          },
          {
            "region_id": "40",
            "hc_key": "ru-kb",
            "short": "Кбр",
            "region": "Кабардино-Балкарская Республика",
            "y": "4",
            "x": "10",
            "mh_city_id": 991,
            "population": "452074",
            "source": "ГТРК Кабардино-Балкария"
          },
          {
            "region_id": "41",
            "hc_key": "ru-kc",
            "short": "Кчр",
            "region": "Карачаево-Черкесская Республика",
            "y": "4",
            "x": "9",
            "mh_city_id": 993,
            "population": "199645",
            "source": "ГТРК Карачаеко-Черкессия"
          },
          {
            "region_id": "42",
            "hc_key": "ru-no",
            "short": "Осет",
            "region": "Республика Северная Осетия",
            "y": "5",
            "x": "10",
            "mh_city_id": 994,
            "population": "445605",
            "source": "ГТРК Алания"
          },
          {
            "region_id": "43",
            "hc_key": "ru-cn",
            "short": "Чеч",
            "region": "Чеченская Республика",
            "y": "6",
            "x": "9",
            "mh_city_id": 361,
            "population": "569730",
            "source": "ГТРК Вайнах"
          },
          {
            "region_id": "44",
            "hc_key": "ru-st",
            "short": "Став",
            "region": "Ставропольский край",
            "y": "5",
            "x": "9",
            "mh_city_id": 217,
            "population": "1652707",
            "source": "ГТРК Ставрополье"
          },
          {
            "region_id": "45",
            "hc_key": "ru-bk",
            "short": "Бшкр",
            "region": "Республика Башкортостан",
            "y": "9",
            "x": "5",
            "mh_city_id": 986,
            "population": "2510951",
            "source": "ГТРК Башкортостан"
          },
          {
            "region_id": "46",
            "hc_key": "ru-me",
            "short": "Мари",
            "region": "Республика Марий Эл",
            "y": "7",
            "x": "3",
            "mh_city_id": 978,
            "population": "455577",
            "source": "ГТРК Марий Эл"
          },
          {
            "region_id": "47",
            "hc_key": "ru-mr",
            "short": "Морд",
            "region": "Республика Мордовия",
            "y": "6",
            "x": "5",
            "mh_city_id": 979,
            "population": "498861",
            "source": "ГТРК Мордовия"
          },
          {
            "region_id": "48",
            "hc_key": "ru-tt",
            "short": "Тат",
            "region": "Республика Татарстан",
            "y": "8",
            "x": "4",
            "mh_city_id": 181,
            "population": "2994450",
            "source": "ГТРК Татарстан"
          },
          {
            "region_id": "49",
            "hc_key": "ru-ud",
            "short": "Удм",
            "region": "Удмуртская Республика",
            "y": "9",
            "x": "4",
            "mh_city_id": 194,
            "population": "988602",
            "source": "ГТРК Удмуртия"
          },
          {
            "region_id": "50",
            "hc_key": "ru-cv",
            "short": "Чув",
            "region": "Чувашская Республика",
            "y": "7",
            "x": "4",
            "mh_city_id": 213,
            "population": "768955",
            "source": "ГТРК Чувашия"
          },
          {
            "region_id": "51",
            "hc_key": "ru-pe",
            "short": "Перм",
            "region": "Пермский край",
            "y": "9",
            "x": "3",
            "mh_city_id": 187,
            "population": "1957552",
            "source": "ГТРК Пермь"
          },
          {
            "region_id": "52",
            "hc_key": "ru-kv",
            "short": "Кир",
            "region": "Кировская область",
            "y": "8",
            "x": "3",
            "mh_city_id": 473,
            "population": "977563",
            "source": "ГТРК Вятка"
          },
          {
            "region_id": "53",
            "hc_key": "ru-nz",
            "short": "Ниж",
            "region": "Нижегородская область",
            "y": "6",
            "x": "4",
            "mh_city_id": 180,
            "population": "2534833",
            "source": "ГТРК Нижний Новгород"
          },
          {
            "region_id": "54",
            "hc_key": "ru-ob",
            "short": "Орен",
            "region": "Оренбургская область",
            "y": "8",
            "x": "6",
            "mh_city_id": 980,
            "population": "1182109",
            "source": "ГТРК Оренбург"
          },
          {
            "region_id": "55",
            "hc_key": "ru-pz",
            "short": "Пенз",
            "region": "Пензенская область",
            "y": "6",
            "x": "6",
            "mh_city_id": 981,
            "population": "892116",
            "source": "ГТРК Пенза"
          },
          {
            "region_id": "56",
            "hc_key": "ru-sa",
            "short": "Сам",
            "region": "Самарская область",
            "y": "8",
            "x": "5",
            "mh_city_id": 184,
            "population": "2514764",
            "source": "ГТРК Самара"
          },
          {
            "region_id": "57",
            "hc_key": "ru-sr",
            "short": "Сар",
            "region": "Саратовская область",
            "y": "7",
            "x": "6",
            "mh_city_id": 982,
            "population": "1813408",
            "source": "ГТРК Саратов"
          },
          {
            "region_id": "58",
            "hc_key": "ru-ul",
            "short": "Уль",
            "region": "Ульяновская область",
            "y": "7",
            "x": "5",
            "mh_city_id": 197,
            "population": "927075",
            "source": "ГТРК Волга"
          },
          {
            "region_id": "59",
            "hc_key": "ru-ku",
            "short": "Кург",
            "region": "Курганская область",
            "y": "11",
            "x": "4",
            "mh_city_id": 233,
            "population": "510098",
            "source": "ГТРК Курган"
          },
          {
            "region_id": "60",
            "hc_key": "ru-sv",
            "short": "Свер",
            "region": "Свердловская область",
            "y": "10",
            "x": "4",
            "mh_city_id": 36,
            "population": "3650725",
            "source": "ГТРК Урал"
          },
          {
            "region_id": "61",
            "hc_key": "ru-ty",
            "short": "Тюм",
            "region": "Тюменская область",
            "y": "11",
            "x": "3",
            "mh_city_id": 192,
            "population": "3068004",
            "source": "ГТРК Регион-Тюмень"
          },
          {
            "region_id": "62",
            "hc_key": "ru-cl",
            "short": "Чел",
            "region": "Челябинская область",
            "y": "10",
            "x": "5",
            "mh_city_id": 182,
            "population": "2846995",
            "source": "ГТРК Южный Урал"
          },
          {
            "region_id": "63",
            "hc_key": "ru-km",
            "short": "Хант",
            "region": "Ханты-Мансийский автономный округ — Югра",
            "y": "10",
            "x": "3",
            "mh_city_id": 539,
            "population": "1563020",
            "source": "ГТРК Югория"
          },
          {
            "region_id": "64",
            "hc_key": "ru-yn",
            "short": "Ямал",
            "region": "Ямало-Ненецкий автономный округ",
            "y": "10",
            "x": "2",
            "mh_city_id": 423,
            "population": "459078",
            "source": "ГТРК Ямал"
          },
          {
            "region_id": "65",
            "hc_key": "ru-ga",
            "short": "Р.Алт",
            "region": "Республика Алтай",
            "y": "12",
            "x": "6",
            "mh_city_id": 360,
            "population": "64504",
            "source": "ГТРК Горный Алтай"
          },
          {
            "region_id": "66",
            "hc_key": "ru-bu",
            "short": "Бур",
            "region": "Республика Бурятия",
            "y": "14",
            "x": "4",
            "mh_city_id": 438,
            "population": "582600",
            "source": "ГТРК Бурятия"
          },
          {
            "region_id": "67",
            "hc_key": "ru-tu",
            "short": "Тыва",
            "region": "Республика Тыва",
            "y": "13",
            "x": "5",
            "mh_city_id": 1080,
            "population": "179471",
            "source": "ГТРК Тыва"
          },
          {
            "region_id": "68",
            "hc_key": "ru-kk",
            "short": "Хак",
            "region": "Республика Хакасия",
            "y": "13",
            "x": "4",
            "mh_city_id": 522,
            "population": "372203",
            "source": "ГТРК Хакасия"
          },
          {
            "region_id": "69",
            "hc_key": "ru-al",
            "short": "Алт.к",
            "region": "Алтайский край",
            "y": "12",
            "x": "5",
            "mh_city_id": 195,
            "population": "1312993",
            "source": "ГТРК Алтай"
          },
          {
            "region_id": "70",
            "hc_key": "ru-ct",
            "short": "Заб",
            "region": "Забайкальский край",
            "y": "14",
            "x": "5",
            "mh_city_id": 446,
            "population": "720040",
            "source": "ГТРК Чита"
          },
          {
            "region_id": "71",
            "hc_key": "ru-ky",
            "short": "Крас",
            "region": "Красноярский край",
            "y": "13",
            "x": "2",
            "mh_city_id": 64,
            "population": "2217054",
            "source": "ГТРК Красноярск"
          },
          {
            "region_id": "72",
            "hc_key": "ru-ir",
            "short": "Ирк",
            "region": "Иркутская область",
            "y": "14",
            "x": "3",
            "mh_city_id": 196,
            "population": "1853799",
            "source": "ГТРК Иркутск"
          },
          {
            "region_id": "73",
            "hc_key": "ru-ke",
            "short": "Кем",
            "region": "Кемеровская область",
            "y": "13",
            "x": "3",
            "mh_city_id": 204,
            "population": "2266449",
            "source": "ГТРК Кузбасс"
          },
          {
            "region_id": "74",
            "hc_key": "ru-ns",
            "short": "Ново",
            "region": "Новосибирская область",
            "y": "12",
            "x": "4",
            "mh_city_id": 179,
            "population": "2207966",
            "source": "ГТРК Новосибирск"
          },
          {
            "region_id": "75",
            "hc_key": "ru-om",
            "short": "Омск",
            "region": "Омская область",
            "y": "11",
            "x": "5",
            "mh_city_id": 183,
            "population": "1388531",
            "source": "ГТРК Иртыш"
          },
          {
            "region_id": "76",
            "hc_key": "ru-to",
            "short": "Томск",
            "region": "Томская область",
            "y": "12",
            "x": "3",
            "mh_city_id": 202,
            "population": "771542",
            "source": "ГТРК Томск"
          },
          {
            "region_id": "77",
            "hc_key": "ru-ck",
            "short": "Саха",
            "region": "Республика Саха (Якутия)",
            "y": "15",
            "x": "2",
            "mh_city_id": 519,
            "population": "651070",
            "source": "ГТРК Саха"
          },
          {
            "region_id": "78",
            "hc_key": "ru-ka",
            "short": "Камч",
            "region": "Камчатский край",
            "y": "17",
            "x": "2",
            "mh_city_id": 474,
            "population": "245188",
            "source": "ГТРК Камчатка"
          },
          {
            "region_id": "79",
            "hc_key": "ru-pr",
            "short": "Прим",
            "region": "Приморский край",
            "y": "16",
            "x": "5",
            "mh_city_id": 200,
            "population": "1453396",
            "source": "ГТРК Владивосток"
          },
          {
            "region_id": "80",
            "hc_key": "ru-kh",
            "short": "Хаб",
            "region": "Хабаровский край",
            "y": "16",
            "x": "3",
            "mh_city_id": 198,
            "population": "1068308",
            "source": "ГТРК Дальневосточная"
          },
          {
            "region_id": "81",
            "hc_key": "ru-am",
            "short": "Амур",
            "region": "Амурская область",
            "y": "15",
            "x": "3",
            "mh_city_id": 261,
            "population": "530465",
            "source": "ГТРК Амур"
          },
          {
            "region_id": "82",
            "hc_key": "ru-mg",
            "short": "Маг",
            "region": "Магаданская область",
            "y": "16",
            "x": "2",
            "mh_city_id": 394,
            "population": "133607",
            "source": "ГТРК Магадан"
          },
          {
            "region_id": "83",
            "hc_key": "ru-sl",
            "short": "Схлн",
            "region": "Сахалинская область",
            "y": "17",
            "x": "4",
            "mh_city_id": 270,
            "population": "400322",
            "source": "ГТРК Сахалин"
          },
          {
            "region_id": "84",
            "hc_key": "ru-yv",
            "short": "Евр",
            "region": "Еврейская автономная область",
            "y": "15",
            "x": "4",
            "mh_city_id": 466,
            "population": "106873",
            "source": "ГТРК Бира"
          },
          {
            "region_id": "85",
            "hc_key": "ru-2485",
            "short": "Чук",
            "region": "Чукотский автономный округ",
            "y": "16",
            "x": "1",
            "mh_city_id": 339,
            "population": "35242",
            "source": "ГТРК Чукотка"
          }
        ]
      },

      toggle_map_type: true,
      toggle_news_type: 0,
      toggle_period: 1,
      toggle_type: 0,
      pageData: {
        title: "ТВ-показатели",
        type: "special-tv"
      },
      hideCityTypeBlock: false
    };
  }
};
</script>

<style lang="scss">
.showNews {
  cursor: pointer;
  text-decoration-line: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
}

.device-percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
}
</style>
